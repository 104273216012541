<template>
  <div class="consumer-review-container">
    <div class="table-container">
      <el-row class="title-container">
        <el-col :span="20">
          <div class="title-text"> {{ $t('accountManagement.DistributeAppManagement') }}</div>
        </el-col>
      </el-row>
      <el-radio-group
        v-if="activePane==='pass'"
        v-model="active_radio_status"
        class="status-radio-container desk-pad-view"
        @change="handleRadioChange"
      >
        <el-radio 
          v-for="(status, index) in device_status_options"
          :key="index"
          :label="status"
        >{{ $t(`accountManagement.${status}`) }}</el-radio>
      </el-radio-group>
      <el-tabs
        v-model="activePane"
        type="card"
        @tab-click="handleTabChange"
      >
        <el-tab-pane name="pass">
          <span slot="label">
            {{ $t(`accountManagement.reviewed`) }} ({{ numPass }})
          </span>
          <el-radio-group
            v-if="activePane==='pass'"
            v-model="active_radio_status"
            class="status-radio-container phone-view"
            @change="handleRadioChange"
          >
            <el-radio 
              v-for="(status, index) in device_status_options"
              :key="index"
              :label="status"
            >{{ $t(`accountManagement.${status}`) }}</el-radio>
          </el-radio-group>
          <el-table
            v-loading="loading"
            class="desk-pad-view"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              align="center"
              :label="$t(`accountManagement.name`)"
            />
            <el-table-column
              prop="tax_id"
              align="center"
              :label="$t(`accountManagement.orgId`)"
            />
            <el-table-column
              v-if="active_radio_status==='all'"
              prop="disabled"
              width="100px"
              align="center"
              :label="$t(`accountManagement.state`)"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.disabled">
                  <el-tag :type="'danger'">
                    {{ $t('accountManagement.inactive') }}
                  </el-tag>
                </template>
                <template v-else>
                  <el-tag :type="'success'">
                    {{ $t('accountManagement.active') }} 
                  </el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="active_radio_status==='inactive'"
              prop="description"
              align="center"
              :label="$t(`accountManagement.description`)"
            />
            <el-table-column
              prop="update_at"
              align="center"
              width="150px"
              :label="$t(`accountManagement.LastUpdateDate`)"
            />
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="80px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
          <el-table
            v-loading="loading"
            class="phone-view"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t(`accountManagement.name`)"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span><br>
                <span class="role-phone-style">{{ scope.row.tax_id }}</span>
                <br><span
                  v-if="active_radio_status==='inactive'"  
                  class="role-phone-style"
                >
                  {{ scope.row.description }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="active_radio_status==='all'"
              prop="disabled"
              width="80px"
              align="center"
              :label="$t(`accountManagement.state`)"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.disabled">
                  <el-tag :type="'danger'">
                    {{ $t('accountManagement.inactive') }}
                  </el-tag>
                </template>
                <template v-else>
                  <el-tag :type="'success'">
                    {{ $t('accountManagement.active') }} 
                  </el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="50px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="pending">
          <span slot="label">
            {{ $t(`accountManagement.reviewing`) }} ({{ numPending }})
          </span>
          <el-table
            v-loading="loading"
            class="desk-pad-view"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              align="center"
              :label="$t(`accountManagement.name`)"
            />
            <el-table-column
              prop="owner_name"
              align="center"
              :label="$t(`accountManagement.OrgOwner`)"
            />
            <el-table-column
              prop="description"
              align="center"
              :label="$t(`accountManagement.description`)"
            />
            <el-table-column
              prop="update_at"
              align="center"
              width="150px"
              :label="$t(`accountManagement.dateApply`)"
            />
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="80px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
          <el-table
            v-loading="loading"
            class="phone-view"
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t(`accountManagement.name`)"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span><br>
                <span class="role-phone-style">{{ scope.row.update_at }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t(`accountManagement.edit`)"
              width="50px"
            >
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  plain
                  size="mini"
                  @click="handleEdit(scope.row)"
                />
              </template>
            </el-table-column>
            <template slot="empty">
              <el-empty :description="$t('general.NoData')" />
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <EditDeviceDialog
      v-if="dialogVisible" 
      :dialog-visible="dialogVisible"
      :data-detail="dataDetail"
      :active-pane="activePane"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import { UTCtoLocalTime } from '@/utils/handleTime'

import EditDeviceDialog from './EditDeviceDialog.vue'

import { getDeviceApplyList } from '@/api/distribute'

export default {
  name: 'DeviceMenagement',
  components: { EditDeviceDialog },
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      active_radio_status: 'active',
      device_status_options: ['active', 'inactive', 'all'],
      dataDetail: {},
      numPass: 0,
      numPending: 0,
      loading: true,
      activePane: 'pass' // pass, pending
    }
  },
  computed: {
    device_status () {
      let device_status = null
      switch (this.active_radio_status) {
        case 'active':
          device_status = false
          break
        case 'inactive':
          device_status = true
          break
        case 'all':
          device_status = null
          break
      }
      return this.activePane === 'pass' ? device_status : null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.fetchGetDeviceList()
    },
    async fetchGetDeviceList() {
      const review_status = this.activePane === 'pass' ? 'pass' : null

      const data = {
        review_status: review_status,
        disabled: this.device_status
      }
      try {
        const res = await getDeviceApplyList(data)
        this.tableData = res.data.result.map((item) => {
          const newObj = {
            ...item,
            name: item.organization_name,
            review_status: true,
            create_at: UTCtoLocalTime(item.create_at, 'YYYY-MM-DD'),
            update_at: UTCtoLocalTime(item.update_at, 'YYYY-MM-DD')
          }
          return newObj
        })
        this.numPass = res.data.reviewed_count
        this.numPending = res.data.reviewing_count
      } catch (error) {
        this.loading = false
        console.error(error)
      }
      this.loading = false
    },
    handleEdit(row, id) {
      this.dialogVisible = true
      this.dataDetail = row
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    showSuccessMessage(message) {
      this.$message({
        title: this.$t('general.Success'),
        message,
        type: 'success'
      })
    },
    emitCloseDialog() {
      this.dialogVisible = false
      this.dataDetail = {}
      this.loadData()
    },
    handleTabChange(tab, event) {
      if (this.activePane === 'pass') {
        this.tableStatus = 'active'
      } else {
        this.tableStatus = 'pending'
      }
      this.fetchGetDeviceList()
    },
    handleRadioChange(value) {
      this.tableStatus = value
      this.fetchGetDeviceList()
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.consumer-review-container{
  width: 100%;
  @media (min-width: 577px) { 
    .desk-pad-view{
      display: block;
    }
    .phone-view{
      display: none;
    } 
  }
  @media (max-width: 576px) { 
    .desk-pad-view{
      display: none;
    }
    .phone-view{
      display: block;
      .role-phone-style{
        font-weight: bold;
        color: $link;
      }
    }
  }
  .el-divider{
    margin: 24px 0 0;
  }
  .table-container{
    padding: 0 20px;
    position: relative;
    
    .title-container{
      margin: 10px 0;
      .title-text{
        font-size: 20px;
        line-height: 2;
        text-align: left;
      }
      .title-right{
        text-align: end;
        .el-button {
          margin-bottom: 5px;
        }
      }
    }
      
      .status-radio-container{
        position: absolute;
        right:20px;
        top:65px;
        z-index: 999;
        @media (max-width: 576px){
          position: initial;
          height: 48px;
          padding-top: 8px;
        }
      }
    
  }
}
</style>
