<template>
  <div class="edit-device">
    <el-dialog 
      :visible="dialogVisible"
      custom-class="edit-device-dialog"
      @close="handleClose"
    >
      <div slot="title">
        {{ $t(`accountManagement.editDeviceForm`) }}
        <el-divider />
      </div>
      <el-form
        ref="editDataForm"
        v-loading="loading"
        :model="editDataForm"
        label-position="top"
      > 
        <el-form-item :label="$t(`accountManagement.name`)" prop="name">
          <el-input v-model="editDataForm.name" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.orgId`)" prop="taxId">
          <el-input v-model="editDataForm.taxId" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.OrgOwner`)" prop="owner_name">
          <el-input v-model="editDataForm.owner_name" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.email`)" prop="email">
          <el-input v-model="editDataForm.email" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.phone`)" prop="phone">
          <el-input v-model="editDataForm.phone" disabled />
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.${isReview ? 'dateApply':'LastUpdateDate'}`)"
          prop="create_at"
        >
          <el-input v-model="editDataForm.update_at" disabled />
        </el-form-item>
        <el-form-item :label="$t(`accountManagement.description`)" prop="description">
          <el-input v-model="editDataForm.description" />
        </el-form-item>
        <template v-if="isReview">
          <el-form-item 
            :label="$t(`accountManagement.review_status`)"
            :label-width="formLabelWidth"
            class="radio-style"
          >
            <el-radio-group v-model="editDataForm.review_status">
              <el-radio :label="true">{{ $t(`accountManagement.pass`) }}</el-radio>
              <el-radio :label="false">{{ $t(`accountManagement.reject`) }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="showRejectReason" :label="'*'+$t(`accountManagement.reviewDescription`)">
            <el-input v-model="editDataForm.review_suggestion" type="textarea" prop="review_suggestion" />
            <span v-if="showNotify" class="reject-notify-container">{{ $t(`accountManagement.inputReasonForReject`) }}</span>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item 
            :label="$t(`accountManagement.state`)"
            :label-width="formLabelWidth"
            class="radio-style"
          >
            <el-radio-group v-model="editDataForm.disabled">
              <el-radio :label="false">{{ $t(`accountManagement.active`) }}</el-radio>
              <el-radio :label="true">{{ $t(`accountManagement.inactive`) }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <div class="dialog-footer">
          <el-button type="info" plain @click="handleClose">{{ $t('general.Cancel') }}</el-button>
          <el-button type="success" plain @click="handleSummit()">{{ $t('general.Save') }}</el-button>
        </div>
        <template v-if="showUnPass">
          <div class="form-divider">
            <span>{{ $t(`accountManagement.reviewRecord`) }}</span>
            <el-divider />
          </div>
          <el-table
            :data="tableData"
            stripe
          >
            <el-table-column
              prop="update_at"
              align="center"
              :label="$t(`accountManagement.dateReview`)"
            />
            <el-table-column
              prop="review_suggestion"
              align="center"
              :label="$t(`accountManagement.Reason`)"
            />
            <el-table-column
              prop="auditor"
              align="center"
              :label="$t(`accountManagement.Auditing`)"
            />
          </el-table>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { UTCtoLocalTime } from '@/utils/handleTime'
import { updateDeviceStatus } from '@/api/distribute'
import { SuccessMessage } from '@/Mixins'

export default {
  name: 'EditDeviceDialog',
  mixins: [SuccessMessage],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dataDetail: {
      type: Object,
      default: () => []
    },
    activePane: {
      type: String,
      default: ''
    },
    tableStatus: {
      type: String, // active, inactive, all, pending
      default: ''
    }
  },
  data () {
    return {
      editDataForm: {
        taxId: '',
        review_suggestion: '',
        description: '',
        update_at: '',
        review_status: false,
        disabled: false
      },
      loading: false,
      tableData: [],
      formLabelWidth: '120px',
      showNotify: false
    }
  },
  computed: {
    isReview() { // include reject and pending
      return this.activePane === 'pending'
    },
    showRejectReason() {
      return !this.editDataForm.review_status
    },
    showUnPass() { // include reject and pending
      return this.isReview && this.dataDetail.review_record.length
    }
  },
  mounted() {
    this.setEditFormData()
  },
  methods: {
    async fetchUpdateDevice(data) {
      this.loading = true
      console.log('data', this.editDataForm.taxId, data)
      try {
        await updateDeviceStatus(this.editDataForm.taxId, data)
        this.showSuccessMessage(this.$t('Notify.Updated'))
        this.handleClose()
      } catch (error) {
        console.error(error)
      }
      this.editDataForm.review_suggestion = ''
      this.loading = false
    },
    reviewDeviceStatus() {
      let newObj = {}
      if (this.editDataForm.review_status) {
        newObj = {
          review_status: 'pass'
        }
        this.editDataForm.review_suggestion = ''
      } else {
        newObj = {
          review_status: 'reject',
          review_suggestion: this.editDataForm.review_suggestion
        }
      }
      this.fetchUpdateDevice(newObj)
    },
    updateDevice() {
      let newObj = {}
      newObj = {
        description: this.editDataForm.description,
        disabled: this.editDataForm.disabled
      }
      this.fetchUpdateDevice(newObj)
    },
    setEditFormData() {
      if (this.isReview) {
        this.editDataForm.update_at = UTCtoLocalTime(this.dataDetail.create_at, 'YYYY-MM-DD')
        this.tableData = this.dataDetail.review_record.map((item) => {
          const newObj = {
            ...item,
            update_at: UTCtoLocalTime(item.create_at, 'YYYY-MM-DD')
          }
          return newObj
        })
      } else {
        this.editDataForm.update_at = UTCtoLocalTime(this.dataDetail.update_at, 'YYYY-MM-DD')
      }
      this.editDataForm = this.dataDetail
      this.editDataForm.taxId = this.dataDetail.tax_id
      this.editDataForm.name = this.dataDetail.name
      this.editDataForm.owner_name = this.dataDetail.owner_name
      this.editDataForm.email = this.dataDetail.email
      this.editDataForm.phone = this.dataDetail.phone
      this.editDataForm.description = this.dataDetail.description
      this.editDataForm.disabled = this.dataDetail.disabled
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    handleSummit() {
      if (this.isReview) {
        if (!this.editDataForm.review_status && !this.editDataForm.review_suggestion) {
          this.showNotify = true
        } else {
          this.reviewDeviceStatus()
        }
      } else {
        this.updateDevice()
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep.el-form--label-top .el-form-item__label{
  padding-bottom: 0 !important;
}
::v-deep .edit-device-dialog .el-dialog__body{
  padding-top: 0!important;
}
::v-deep.el-divider--horizontal {
  margin-bottom: 0;
}
.edit-device{
  ::v-deep .edit-device-dialog{
    @media (max-width: 576px) {
      width: 90% ;
      min-width: 280px;
    }

    .dialog-footer{
      display: flex;
      justify-content: end;
    }
    .form-divider{
      display: flex;
      span{
        width: 120px;
        text-align: right;
        padding: 0 12px 0 0;
        line-height: 60px;
      }
      .el-divider--horizontal{
        margin-bottom: 0;
      }
      .el-divider{
        margin: 30px 0;
      }
    }
    .suggestion-container{
      min-height: 40px;
      display: flex;
      .suggestion-label{
        display: flex;
        min-width: 120px;
        padding: 0 12px;
        justify-content: flex-end;
        box-sizing: border-box;
      }
      .suggestion-value{
        width: fit-content;
        padding: 0 15px;
      }
    }
    .reject-notify-container{
      color: $danger;
    }
  } 

  .radio-style{
    text-align: left;
    min-height: 40px;
  }
}
</style>
